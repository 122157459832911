import { SvgIcon, SvgIconProps } from '@mui/material'

export const PhoneCallAI: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <svg
        width={props.width}
        height={props.height}
        className={props.className}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.0601 21.7099C13.8001 21.7099 10.8701 19.9199 7.35008 16.3999C3.83008 12.8799 2.02008 9.91994 2.04008 7.65994C2.04008 6.14994 2.84009 5.37994 3.14009 5.08994C3.16009 5.06994 3.20007 5.01995 3.22007 5.00995C4.51007 3.71995 5.39008 3.18994 6.26008 3.18994C6.26008 3.18994 6.28008 3.18994 6.29008 3.18994C7.32008 3.19994 8.05006 3.95993 8.96006 4.92993C9.18006 5.15993 9.42007 5.41994 9.69007 5.68994C11.0501 7.04994 10.5801 8.24994 10.2301 9.12994C9.83008 10.1499 9.51008 10.9599 11.1601 12.5999C12.8001 14.2399 13.6101 13.9299 14.6301 13.5199C15.5101 13.1799 16.7101 12.6999 18.0701 14.0599C18.3401 14.3299 18.5901 14.5699 18.8201 14.7899C19.7901 15.7099 20.5501 16.4399 20.5601 17.4699C20.5701 18.3499 20.0501 19.2399 18.7401 20.5399L18.1201 20.0999L18.6601 20.6199C18.3701 20.9199 17.6001 21.7099 16.0801 21.7199H16.0601V21.7099ZM4.29008 6.05994C4.29008 6.05994 4.25007 6.10994 4.19007 6.15994C3.97007 6.37994 3.55008 6.77995 3.54008 7.66995C3.54008 8.93995 4.37009 11.2999 8.42009 15.3399C12.4401 19.3599 14.8001 20.2099 16.0701 20.2099H16.0901C16.9701 20.2099 17.3801 19.7899 17.6001 19.5599C17.6701 19.4899 17.7201 19.4399 17.7601 19.4099C18.6301 18.5299 19.0801 17.8799 19.0701 17.4799C19.0701 17.08 18.5301 16.5699 17.7901 15.8699C17.5501 15.6399 17.2901 15.3899 17.0101 15.1199C16.3501 14.4599 16.0101 14.59 15.1801 14.92C14.0201 15.37 12.4301 15.9999 10.1001 13.6599C7.76008 11.3299 8.38007 9.73993 8.84007 8.57993C9.17007 7.74993 9.30009 7.40993 8.64009 6.73993C8.36009 6.45993 8.11008 6.19993 7.88008 5.95993C7.18008 5.22993 6.68007 4.69994 6.28007 4.68994C5.87007 4.68994 5.22006 5.13995 4.30006 6.06995L4.29008 6.05994Z"
          fill="currentColor"
        />
        <path
          d="M18.6201 10.56C18.3101 10.56 18.0302 10.37 17.9202 10.07L17.8202 9.79999C17.5102 8.96999 16.8702 8.31999 16.0402 8.01999L15.7701 7.92001C15.4801 7.81001 15.2802 7.53 15.2802 7.22C15.2802 6.91 15.4801 6.62999 15.7701 6.51999L16.0402 6.42001C16.8602 6.11001 17.5102 5.46001 17.8202 4.64001L17.9202 4.37C18.0302 4.08 18.3101 3.88 18.6201 3.88C18.9301 3.88 19.2102 4.08 19.3202 4.37L19.4202 4.64001C19.7202 5.47001 20.3702 6.12001 21.2002 6.42001L21.4702 6.51999C21.7602 6.62999 21.9601 6.91 21.9601 7.22C21.9601 7.53 21.7702 7.81001 21.4702 7.92001L21.2002 8.01999C20.3702 8.32999 19.7302 8.97999 19.4202 9.79999L19.3202 10.07C19.2102 10.36 18.9301 10.56 18.6201 10.56ZM17.6702 7.20999C18.0302 7.47999 18.3501 7.8 18.6201 8.16C18.8901 7.8 19.2102 7.47999 19.5702 7.20999C19.2102 6.93999 18.8901 6.62001 18.6201 6.26001C18.3501 6.62001 18.0302 6.93999 17.6702 7.20999Z"
          fill="currentColor"
        />
        <path
          d="M13.4601 6.14005C13.1301 6.14005 12.8401 5.92002 12.7401 5.61002C12.6401 5.28002 12.3902 5.03002 12.0602 4.93002C11.7402 4.83002 11.5302 4.54002 11.5302 4.21002C11.5302 3.88002 11.7502 3.59002 12.0602 3.49002C12.3902 3.39002 12.6401 3.14003 12.7401 2.81003C12.8401 2.49003 13.1301 2.28003 13.4601 2.28003C13.7901 2.28003 14.0801 2.50003 14.1801 2.81003C14.2801 3.14003 14.5301 3.39002 14.8601 3.49002C15.1801 3.59002 15.3902 3.88002 15.3902 4.21002C15.3902 4.54002 15.1701 4.83002 14.8601 4.93002C14.5301 5.03002 14.2801 5.28002 14.1801 5.61002C14.0801 5.93002 13.7901 6.14005 13.4601 6.14005Z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  )
}
