import {
  bg,
  cs,
  da,
  de,
  el,
  enGB,
  es,
  et,
  fi,
  fr,
  frCA,
  he,
  hi,
  hr,
  hu,
  is,
  it,
  ja,
  ko,
  lt,
  lv,
  ms,
  nb,
  nl,
  nlBE,
  pl,
  pt,
  ptBR,
  ro,
  ru,
  sk,
  sl,
  sq,
  sr,
  sv,
  th,
  tr,
  vi,
  zhCN,
  zhHK,
} from 'date-fns/locale'

export const getCurrentLocale = (language: string) => {
  switch (language) {
    case 'fr':
      return fr
    case 'it':
      return it
    case 'pt':
      return pt
    case 'nl':
      return nl
    case 'de':
      return de
    case 'es':
      return es
    case 'sv':
      return sv
    case 'el':
      return el
    case 'pl':
      return pl
    case 'ms':
      return ms
    case 'vi':
      return vi
    case 'ko':
      return ko
    case 'ro':
      return ro
    case 'hu':
      return hu
    case 'bg':
      return bg
    case 'frCA':
      return frCA
    case 'ptBR':
      return ptBR
    case 'trTR':
      return tr
    case 'daDK':
      return da
    case 'noNO':
      return nb
    case 'ruRU':
      return ru
    case 'fiFI':
      return fi
    case 'sqAL':
      return sq
    case 'zhHK':
      return zhHK
    case 'hrHR':
      return hr
    case 'csCZ':
      return cs
    case 'etEE':
      return et
    case 'heIL':
      return he
    case 'hiIN':
      return hi
    case 'jaJP':
      return ja
    case 'lvLV':
      return lv
    case 'ltLT':
      return lt
    case 'zhCN':
      return zhCN
    case 'thTH':
      return th
    case 'srRS':
      return sr
    case 'skSK':
      return sk
    case 'slSI':
      return sl
    case 'nlBE':
      return nlBE
    case 'isIS':
      return is
    case 'esMX':
      return es
    default:
      return enGB
  }
}
