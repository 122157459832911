import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const AiBrainSearch: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <svg
      id="Ai brain search"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.1872 19.3145C14.1962 19.8355 13.8896 21.5009 13.8896 21.5009"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M11.5103 2.50345C13.3868 2.46169 15.168 2.78923 16.6348 4.02633C18.5178 5.61445 19.6192 7.55752 19.0489 10.1413C18.745 11.5186 19.7505 13.083 20.463 14.0401C20.7767 14.4615 20.6186 15.026 20.1285 15.215L19.201 15.5727C18.9334 15.6759 18.7379 15.9098 18.6838 16.1914L18.3736 18.8346C18.2707 19.5205 17.6876 19.8464 17.0359 19.7092L14.2515 19.0814C13.341 18.8807 13.3809 18.0571 13.143 17.1952"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M4.30859 12.8633C5.7638 15.468 6.43596 18.4308 5.19335 21.2646"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M8.16447 8.25333C6.94206 9.4748 4.96175 9.4748 3.73934 8.25333C2.51787 7.03187 2.51787 5.05062 3.73934 3.82821C4.96175 2.60674 6.94206 2.60674 8.16447 3.82821C9.38594 5.05062 9.38594 7.03187 8.16447 8.25333ZM8.16447 8.25333L9.85932 9.94865"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </SvgIcon>
)
