import { useTheme } from '@mui/material'
import React, { useContext, useEffect, useRef } from 'react'

import { HardwareContext } from '../providers/HardwareProvider'

const BAR_COUNT = 14
const BAR_GAP = 36

export const MicrophoneVisualizer = () => {
  const theme = useTheme()
  const { audioInputId } = useContext(HardwareContext)
  const canvasRef = useRef<HTMLCanvasElement | null>(null)
  const audioContextRef = useRef<AudioContext | null>(null)
  const analyserRef = useRef<AnalyserNode | null>(null)
  const dataArrayRef = useRef<Uint8Array | null>(null)
  const animationFrameIdRef = useRef<number | null>(null)

  useEffect(() => {
    const initMicrophoneVisualizer = async () => {
      try {
        if (!audioInputId) {
          return
        }

        const stream = await navigator.mediaDevices.getUserMedia({
          audio: { deviceId: { exact: audioInputId } },
        })

        audioContextRef.current = new AudioContext()
        const source = audioContextRef.current.createMediaStreamSource(stream)

        analyserRef.current = audioContextRef.current.createAnalyser()
        analyserRef.current.smoothingTimeConstant = 0.8
        analyserRef.current.fftSize = 256

        dataArrayRef.current = new Uint8Array(analyserRef.current.frequencyBinCount)

        source.connect(analyserRef.current)

        const canvas = canvasRef.current
        const canvasCtx = canvas?.getContext('2d')

        if (canvas && canvasCtx) {
          const draw = () => {
            if (analyserRef.current && dataArrayRef.current) {
              canvasCtx.clearRect(0, 0, canvas.width, canvas.height)

              analyserRef.current.getByteFrequencyData(dataArrayRef.current)
              const arraySum = dataArrayRef.current.reduce((a, value) => a + value, 0)
              const volume = arraySum / dataArrayRef.current.length / 100

              // Draw bars based on volume
              const barWidth = Math.round(canvas.width / BAR_COUNT - BAR_GAP)
              const volumeBarCount = volume * BAR_COUNT
              const volumeMaxIndex = volumeBarCount < 1 ? 1 : volumeBarCount

              const gradient = canvasCtx.createLinearGradient(0, 0, canvas.width, 0)
              gradient.addColorStop(0, theme.palette.primary.main)
              // gradient.addColorStop(0, colorOpacityModifier(theme.palette.primary.main, 0.5))
              gradient.addColorStop(1, theme.palette.primary.main)

              for (let i = 0; i < BAR_COUNT; i++) {
                const x = Math.round(i * (barWidth + BAR_GAP))

                let fillStyle
                if (i < volumeMaxIndex) {
                  fillStyle = gradient
                } else {
                  fillStyle = theme.palette.grey[300]
                }

                canvasCtx.fillStyle = fillStyle
                canvasCtx.beginPath()
                canvasCtx.roundRect(x, 0, barWidth, canvas.height, 24)
                canvasCtx.fill()
              }
            }
            animationFrameIdRef.current = requestAnimationFrame(draw)
          }

          draw()
        }
      } catch (err) {
        // do nothing for now
      }
    }

    initMicrophoneVisualizer()

    return () => {
      if (audioContextRef.current) {
        audioContextRef.current.close()
      }
      if (animationFrameIdRef.current) {
        cancelAnimationFrame(animationFrameIdRef.current)
      }
    }
  }, [audioInputId, theme.palette.grey, theme.palette.primary.main])

  // We make the canvas bigger to have clean UI
  // Otherwise we have pixelized rectangle
  return (
    <canvas
      ref={canvasRef}
      width="1800px"
      height="180px"
      style={{
        width: '300px',
        height: '30px',
      }}
    />
  )
}
