import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { useEffect, useState } from 'react'

type ListZipcodesOutput = {
  country_code: string
  latitude: number
  longitude: number
  place_name: string
  zipcode: string
}

export const useSearchGbZipcodes = (inputValue: string, enabled: boolean) => {
  const [zipcodes, setZipcodes] = useState<ListZipcodesOutput[]>([])

  const { data: autocompleteData } = useQuery(
    ['gbZipcodesAutocomplete', inputValue],
    () => axios.get(`https://api.postcodes.io/postcodes/${inputValue}/autocomplete`),
    {
      enabled,
      staleTime: Infinity,
      cacheTime: Infinity,
    },
  )

  const { data: lookupData } = useQuery(
    ['gbZipcodesLookup', autocompleteData?.data.result],
    () =>
      axios.post('https://api.postcodes.io/postcodes', {
        postcodes: autocompleteData?.data.result,
      }),
    {
      enabled: enabled && autocompleteData?.data?.result?.length > 0,
      staleTime: Infinity,
      cacheTime: Infinity,
    },
  )

  useEffect(() => {
    if (lookupData) {
      setZipcodes(
        lookupData.data.result.map(
          ({
            result: { postcode, admin_district, latitude, longitude },
          }: {
            result: {
              postcode: string
              admin_district: string
              latitude: number
              longitude: number
            }
          }) => ({
            country_code: 'GB',
            latitude,
            longitude,
            place_name: admin_district,
            zipcode: postcode,
          }),
        ),
      )
    }
  }, [lookupData])

  return zipcodes
}
