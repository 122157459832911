import { format as formatDate } from 'date-fns'
import * as dateFnsLocales from 'date-fns/locale'
import i18next, { InitOptions, Resource, ResourceLanguage } from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

export const DEFAULT_LANGUAGE = 'en'

const CDN_LANGUAGES = {
  en: 'en_US',
  fr: 'fr_FR',
  de: 'de_DE',
  es: 'es_ES',
  pt: 'pt_PT',
  nl: 'nl_NL',
  it: 'it_IT',
  sv: 'sv_SE',
  el: 'el_GR',
  pl: 'pl_PL',
  ms: 'ms_ID',
  vi: 'vi_VN',
  ko: 'ko_KR',
  ro: 'ro_RO',
  hu: 'hu_HU',
  bg: 'bg_BG',
  frCA: 'fr_CA',
  ptBR: 'pt_BR',
  trTR: 'tr_TR',
  daDK: 'da_DK',
  noNO: 'no_NO',
  ruRU: 'ru_RU',
  fiFI: 'fi_FI',
  sqAL: 'sq_AL',
  zhHK: 'zh_HK',
  hrHR: 'hr_HR',
  csCZ: 'cs_CZ',
  etEE: 'et_EE',
  heIL: 'he_IL',
  hiIN: 'hi_IN',
  jaJP: 'ja_JP',
  lvLV: 'lv_LV',
  ltLT: 'lt_LT',
  zhCN: 'zh_CN',
  thTH: 'th_TH',
  srRS: 'sr_RS',
  skSK: 'sk_SK',
  slSI: 'sl_SI',
  nlBE: 'nl_BE',
  isIS: 'is_IS',
  esMX: 'es_MX',
  arSA: 'ar_SA',
  ukUA: 'uk_UA',
  caES: 'ca_ES',
}

export const SUPPORTED_LANGUAGES = Object.keys(CDN_LANGUAGES)

const CDN_BASE_URL = process.env.NX_CDN_URL_TRANSLATIONS

interface TranslationResource extends ResourceLanguage {
  locale: Locale
  cdn: string
}

type Resources = Record<string, TranslationResource>

const resources: Resources = Object.entries(CDN_LANGUAGES).reduce((acc, [lang, cdnLang]) => {
  acc[lang] = {
    translation: {},
    locale: (dateFnsLocales as Record<string, Locale>)[lang] || dateFnsLocales.enUS,
    cdn: cdnLang,
  }
  return acc
}, {} as Resources)

const translationCache: Record<string, Record<string, unknown>> = {}

const fetchTranslation = async (lang: string): Promise<Record<string, unknown>> => {
  const response = await fetch(
    `${CDN_BASE_URL}/${CDN_LANGUAGES[lang as keyof typeof CDN_LANGUAGES]}/${CDN_LANGUAGES[lang as keyof typeof CDN_LANGUAGES]}.json`,
    {
      headers: {
        'Cache-Control': 'no-cache',
      },
    },
  )
  if (!response.ok) {
    throw new Error(`Failed to fetch translation for ${lang}`)
  }
  const translation = await response.json()
  translationCache[lang] = translation
  return translation
}

export const loadTranslation = async (lang: string): Promise<void> => {
  try {
    let translation = i18next.getResourceBundle(lang, 'translation')

    if (translationCache[lang]) {
      translation = translationCache[lang]
    } else {
      translation = await fetchTranslation(lang)
    }
    resources[lang].translation = translation
    i18next.addResourceBundle(lang, 'translation', translation, true, true)
    await i18next.changeLanguage(lang)
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(`Failed to load translation for ${lang}:`, error)
  }
}
const initI18n = async (): Promise<void> => {
  const i18nOptions: InitOptions = {
    partialBundledLanguages: true,
    resources: resources as unknown as Resource,
    supportedLngs: SUPPORTED_LANGUAGES,
    fallbackLng: DEFAULT_LANGUAGE,
    interpolation: {
      escapeValue: false,
      format(value, format: string | undefined, lng: string | undefined) {
        if (format === 'uppercase') {
          return value.toUpperCase()
        }
        if (value instanceof Date) {
          return formatDate(value, format || '', {
            locale: resources[(lng || DEFAULT_LANGUAGE) as keyof Resources].locale,
          })
        }
        if (format === 'price') {
          return new Intl.NumberFormat(lng, {
            style: 'currency',
            currency: value.currency || 'EUR',
          }).format(value.price / 100)
        }
        if (format === 'date') {
          return new Intl.DateTimeFormat(
            resources[(lng || DEFAULT_LANGUAGE) as keyof Resources].locale.code,
            value.format,
          ).format(value.date)
        }
        return value
      },
    },
  }

  await i18next.use(initReactI18next).use(LanguageDetector).init(i18nOptions)

  await loadTranslation(DEFAULT_LANGUAGE)

  // Override the plural resolver
  const current = i18next.services.pluralResolver
  i18next.services.pluralResolver.getRule = (code: string, options = { ordinal: false }) => {
    if (current.shouldUseIntlApi()) {
      try {
        const cleanedCode = code === 'dev' ? 'en' : code.replace(/^([a-z]{2})([A-Z]{2})$/, '$1-$2')
        const type = options.ordinal ? 'ordinal' : 'cardinal'
        const cacheKey = JSON.stringify({ cleanedCode, type })
        if (cacheKey in current.pluralRulesCache) {
          return current.pluralRulesCache[cacheKey]
        }
        const rule = new Intl.PluralRules(cleanedCode, { type })
        current.pluralRulesCache[cacheKey] = rule
        return rule
      } catch {
        return undefined
      }
    }
    return current.rules[code] || current.rules[current.languageUtils.getLanguagePartFromCode(code)]
  }
}

initI18n()

export const i18n = i18next
