import {
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  Theme,
  useMediaQuery,
} from '@mui/material'
import { CSSProperties } from '@mui/styled-engine'
import { useCallback, useContext } from 'react'

import { HardwareContext } from '../providers/HardwareProvider'

type MicrophoneSelectorProps = {
  theme: Theme
  brandingTheme?: CSSProperties
  Icon?: React.ReactNode
}
export const MicrophoneSelector = ({ theme, brandingTheme }: MicrophoneSelectorProps) => {
  const belowSmBreakpoint = useMediaQuery(theme.breakpoints.down('sm'))
  const { audioInputId, audioInputDevices, setAudioInputId } = useContext(HardwareContext)

  const handleSetAudioInputId = useCallback(
    (event: SelectChangeEvent<string | null>) => {
      event.preventDefault()
      if (event.target.value) {
        setAudioInputId(event.target.value)
      }
    },
    [setAudioInputId],
  )

  return (
    <FormControl fullWidth>
      <Select
        data-testid="microphone-select"
        labelId="microphone-select-label"
        value={audioInputId}
        size={belowSmBreakpoint ? 'small' : 'medium'}
        sx={{
          maxWidth: { xs: '92%', sm: 'initial' },
          ...brandingTheme,
        }}
        onChange={handleSetAudioInputId}
      >
        {audioInputDevices.map((input) => (
          <MenuItem key={input.deviceId} value={input.deviceId}>
            {input.label || `Microphone ${input.deviceId}`}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
