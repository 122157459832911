import { usePreferredMediaMimeType } from './usePreferredMediaMimeType'

const MOBILE_VIDEO_CONSTRAINTS = {
  width: 320,
  height: 240,
  frameRate: { ideal: 24, max: 30 },
}

const DESKTOP_VIDEO_CONSTRAINTS = {
  width: 466,
  height: 350,
  frameRate: { ideal: 24, max: 30 },
}

export const useVideoRecordingConstraint = (isDesktop: boolean) => {
  const mimeType = usePreferredMediaMimeType('video')
  return {
    videoConstraints: isDesktop ? DESKTOP_VIDEO_CONSTRAINTS : MOBILE_VIDEO_CONSTRAINTS,
    mediaRecorderOptions: {
      mimeType: mimeType?.name,
      videoBitsPerSecond: 1000000, // 1 Mbps
      audioBitsPerSecond: 128000, // 128 kbps
    },
    mimeType,
  }
}
