import { Box, MenuItem, Select, SelectChangeEvent, Theme, useMediaQuery } from '@mui/material'
import { CSSProperties } from '@mui/styled-engine'
import { useCallback, useContext } from 'react'

import { HardwareContext } from '../providers/HardwareProvider'

type AudioOutputSelectorProps = {
  theme: Theme
  brandingTheme: CSSProperties
}
export const AudioOutputSelector = ({ theme, brandingTheme }: AudioOutputSelectorProps) => {
  const belowSmBreakpoint = useMediaQuery(theme.breakpoints.down('sm'))
  const { audioOutputId, audioOutputDevices, setAudioOutputId } = useContext(HardwareContext)

  const handleSetAudioInputId = useCallback(
    (event: SelectChangeEvent<string | null>) => {
      event.preventDefault()
      if (event.target.value) {
        setAudioOutputId(event.target.value)
      }
    },
    [setAudioOutputId],
  )

  return (
    <Box display="flex" gap={theme.spacing(2)}>
      <Select
        data-testid="microphone-select"
        labelId="microphone-select-label"
        value={audioOutputId}
        size={belowSmBreakpoint ? 'small' : 'medium'}
        sx={{
          maxWidth: { xs: '92%', sm: 'initial' },
          ...brandingTheme,
        }}
        onChange={handleSetAudioInputId}
      >
        {audioOutputDevices.map((output) => (
          <MenuItem key={output.deviceId} value={output.deviceId}>
            {output.label || `Speaker ${output.deviceId}`}
          </MenuItem>
        ))}
      </Select>
    </Box>
  )
}
