import { i18n } from '@maki/shared/utils'
import i18next from 'i18next'

export const useLanguageDirection = () => {
  // ii18next.dir() works only with the first two characters of the locale
  const direction = i18next.dir(i18n.language.slice(0, 2)) ?? 'ltr'
  // initialize the direction of the document
  if (!document.documentElement.dir) {
    document.documentElement.dir = direction
  }

  const hasDirectionChanged = (newLanguage: string) => {
    return document.documentElement.dir !== i18next.dir(newLanguage.slice(0, 2))
  }

  return { direction, hasDirectionChanged }
}
