import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const HomeAi: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <svg
      id="Home ai "
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.8718 3.66551C12.782 2.77816 11.2185 2.77816 10.1287 3.66551L4.55457 8.20638C3.86376 8.76973 3.46289 9.6133 3.46289 10.5045V17.6783C3.46289 19.5123 4.94959 21 6.78462 21H17.2159C19.0509 21 20.5376 19.5123 20.5376 17.6783V11.5045"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.63585 21V16.0833C9.63585 15.5938 10.0325 15.1971 10.522 15.1971H13.4785C13.968 15.1971 14.3646 15.5938 14.3646 16.0833V21"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.1272 9.62561L18.2332 9.33872C18.6329 8.25698 19.4846 7.40408 20.5648 7.0038L20.8512 6.89764L20.5648 6.79149C19.4846 6.39121 18.6329 5.53831 18.2332 4.45656L18.1272 4.16965L18.0212 4.45656C17.6215 5.53831 16.7699 6.39121 15.6897 6.79149L15.4032 6.89764L15.6897 7.0038C16.7699 7.40408 17.6215 8.25698 18.0212 9.33872L18.1272 9.62561Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.8775 11.4711C13.0576 10.8785 13.5207 10.4147 14.1123 10.2344C13.5207 10.054 13.0576 9.59025 12.8775 8.99767C12.6974 9.59025 12.2344 10.054 11.6427 10.2344C12.2344 10.4147 12.6974 10.8785 12.8775 11.4711Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </SvgIcon>
)
