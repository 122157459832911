import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const WheelchairOutline: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <svg
      id="Wheelchair"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.3724 17.8331C14.2724 19.2901 12.5864 20.1251 10.7464 20.1251C7.54145 20.1251 4.93445 17.5191 4.93445 14.3141C4.93445 11.8471 6.49745 9.64409 8.82245 8.83309C9.21345 8.69709 9.41945 8.27009 9.28345 7.87809C9.14645 7.48609 8.72045 7.28209 8.32845 7.41709C5.40145 8.43709 3.43445 11.2091 3.43445 14.3141C3.43445 18.3461 6.71445 21.6251 10.7464 21.6251C13.0614 21.6251 15.1844 20.5721 16.5694 18.7371C16.8194 18.4061 16.7534 17.9371 16.4224 17.6871C16.0934 17.4371 15.6224 17.5031 15.3724 17.8331Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.385 6.755C13.592 6.755 14.574 5.773 14.574 4.565C14.574 3.358 13.592 2.375 12.385 2.375C11.178 2.375 10.196 3.358 10.196 4.565C10.196 5.773 11.178 6.755 12.385 6.755Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.5114 17.1922C20.4764 17.0802 20.4194 16.9732 20.3624 16.8702C19.6594 15.5792 19.5464 15.3422 18.8554 14.0452C18.5774 13.5222 18.1494 13.3082 17.5884 13.3062C16.6384 13.3022 15.6884 13.3052 14.7394 13.3052H14.2524C14.2244 12.9402 14.2084 12.3892 14.1874 12.0792H16.9314C17.4474 12.0792 17.8654 11.6612 17.8654 11.1452C17.8654 10.6302 17.4474 10.2122 16.9314 10.2122H14.0674C14.0424 9.97822 14.0174 9.79422 13.9964 9.51122C13.9444 8.83622 13.4894 8.34122 12.8314 8.24122C11.7724 8.08122 11.0144 8.74122 11.0714 9.81222C11.1494 11.2952 11.2494 12.7782 11.3554 14.2602C11.4194 15.1592 11.9254 15.6182 12.8394 15.6232C14.2634 15.6302 15.6884 15.6372 17.1124 15.6182C17.4424 15.6142 17.6114 15.7232 17.7614 16.0122C18.2404 16.9342 18.1504 16.7922 18.6504 17.7032C18.7144 17.8202 18.7754 17.9412 18.8554 18.0472C19.1654 18.4592 19.6694 18.5942 20.0764 18.3842C20.4824 18.1752 20.6654 17.6822 20.5114 17.1922Z"
        fill="currentColor"
      />
    </svg>
  </SvgIcon>
)
