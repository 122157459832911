import { useQueryClient } from '@tanstack/react-query'
import { useEffect, useRef, useState } from 'react'
import { v4 as uuid } from 'uuid'

import { CustomGraphQLClient } from '../customGraphlClient'

let currentGraphqlClientInstance: CustomGraphQLClient
let currentGraphqlURL: string | undefined

export const setSubscriptionGraphqlClientInstance = (
  client: CustomGraphQLClient,
  graphqlURL?: string,
) => {
  currentGraphqlClientInstance = client
  currentGraphqlURL = graphqlURL
}

type useSubscriptionProps<Variables> = {
  operationName: string
  subscriptionQuery: string
  cacheKey: (string | Variables)[]
  fragments?: string[]
  variables?: Variables
}

export const USE_QUERY_FOR_SUBSCRIPTION_DEFAULT_OPTIONS = {
  refetchOnMount: false,
  refetchOnWindowFocus: false,
  refetchOnReconnect: false,
  refetchIntervalInBackground: false,
  enabled: false,
}

export const useSubscription = <Variables>({
  operationName,
  subscriptionQuery,
  cacheKey,
  fragments = [],
  variables,
}: useSubscriptionProps<Variables>) => {
  const [isSubscribing, setIsSubscribing] = useState(false)
  const [isSubscribingSuccess, setIsSubscribingSuccess] = useState(false)
  const [id] = useState(uuid())
  const queryClient = useQueryClient()

  const wsRef = useRef<WebSocket | null>(null)
  const reconnectDelay = useRef(2000) // Reconnection delay (can increase if needed)

  // Function to initialize WebSocket connection
  const connectWebSocket = () => {
    if (!currentGraphqlURL) {
      return
    }

    const ws = new WebSocket(currentGraphqlURL, 'graphql-ws')
    wsRef.current = ws
    setIsSubscribing(true)

    ws.onopen = () => {
      ws.send(
        JSON.stringify({
          type: 'connection_init',
          payload: {
            headers: currentGraphqlClientInstance.getHeaders(),
          },
        }),
      )
      ws.send(
        JSON.stringify({
          id,
          type: 'start',
          payload: {
            variables,
            extensions: {},
            operationName,
            query: `${fragments?.join(' ')} ${subscriptionQuery}`,
          },
        }),
      )
    }

    ws.onmessage = (event) => {
      const msg = JSON.parse(event.data)

      if (msg.type === 'data') {
        setIsSubscribingSuccess(true)
        setIsSubscribing(false)

        queryClient.setQueriesData(cacheKey, msg.payload.data)
      }
    }

    // Handle WebSocket close and attempt reconnection
    ws.onclose = () => {
      setTimeout(() => {
        if (wsRef.current !== null) {
          connectWebSocket() // Reconnect after delay
        }
      }, reconnectDelay.current)

      // Add an exponential backoff to the reconnect delay
      reconnectDelay.current = Math.min(reconnectDelay.current * 2, 30000) // Cap at 30 seconds
    }
  }

  useEffect(() => {
    connectWebSocket()

    return () => {
      const currentWs = wsRef.current

      // We remove the ref to the WebSocket instance to avoid reconnecting
      wsRef.current = null

      if (currentWs && currentWs.readyState === WebSocket.OPEN) {
        currentWs.send(JSON.stringify({ id, type: 'stop' }))
      }

      currentWs?.close()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { isSubscribing, isSubscribingSuccess }
}
