import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const Chat: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <svg
      id="Chat"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.1245 2.37281C9.75314 2.56861 8.03934 3.26165 6.86324 4.09609C6.131 4.61562 4.9112 5.88308 4.40134 6.65431C3.21603 8.44675 2.61906 10.8299 2.84827 12.8538C3.00495 14.2366 3.59874 16.0701 4.29243 17.3133C4.42552 17.5518 4.53443 17.7911 4.53443 17.8451C4.53443 17.8991 4.02973 18.7519 3.41284 19.7401C2.79595 20.7283 2.26508 21.5815 2.23307 21.636C2.19462 21.7016 4.15369 21.7318 8.03705 21.7253C13.628 21.7158 13.9382 21.7058 14.7415 21.5086C20.3211 20.1382 23.5172 14.5016 21.8051 9.0515C20.3866 4.53625 15.8486 1.6986 11.1245 2.37281ZM14.0705 3.89059C17.3792 4.52357 19.9753 7.09001 20.6437 10.3887C20.9011 11.6587 20.8057 13.3202 20.4045 14.554C19.6977 16.7283 18.2607 18.3819 16.1785 19.4173C14.4989 20.2525 14.0596 20.3053 9.04697 20.2746L4.82935 20.2487L5.57555 19.0479L6.32177 17.8471L5.90625 17.1652C5.37548 16.2939 4.91566 15.2339 4.57892 14.1051C4.3615 13.3764 4.31126 13.0048 4.30383 12.0738C4.28569 9.77219 5.01139 7.94744 6.60301 6.29283C7.87127 4.97433 9.6253 4.05566 11.3227 3.82101C12.0144 3.72529 13.3888 3.76008 14.0705 3.89059ZM8.10197 12.4247V13.1721L8.86998 13.1431L9.63799 13.1142L9.66712 12.3958L9.69626 11.6774H8.89911H8.10197V12.4247ZM11.7686 12.4206V13.1638H12.5118H13.2551V12.4206V11.6774H12.5118H11.7686V12.4206ZM15.3566 12.3958L15.3857 13.1142L16.1537 13.1431L16.9217 13.1721V12.4247V11.6774H16.1246H15.3274L15.3566 12.3958Z"
        fill="currentColor"
      />
    </svg>
  </SvgIcon>
)
