import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const NumberedList: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <svg
      id="Numbered list"
      width="24"
      height="25"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.90137 21.2656H5.21039C5.0521 21.2656 4.89788 21.2156 4.76978 21.1226C4.64167 21.0297 4.54625 20.8985 4.49719 20.748C4.44814 20.5976 4.44793 20.4354 4.49664 20.2848C4.54535 20.1342 4.64048 20.0029 4.76837 19.9096C4.78837 19.8956 6.75138 18.4626 7.45038 17.8346C7.65267 17.6758 7.81944 17.4764 7.94 17.2493C8.06056 17.0221 8.13226 16.7722 8.15039 16.5156C8.15039 16.1841 8.01866 15.8661 7.78424 15.6317C7.54982 15.3973 7.23191 15.2656 6.90039 15.2656C6.56887 15.2656 6.2509 15.3973 6.01648 15.6317C5.78206 15.8661 5.65039 16.1841 5.65039 16.5156C5.65039 16.7145 5.57138 16.9053 5.43073 17.046C5.29007 17.1866 5.0993 17.2656 4.90039 17.2656C4.70148 17.2656 4.51071 17.1866 4.37006 17.046C4.2294 16.9053 4.15039 16.7145 4.15039 16.5156C4.15039 15.7863 4.44009 15.0868 4.95581 14.5711C5.47154 14.0554 6.17105 13.7656 6.90039 13.7656C7.62974 13.7656 8.32918 14.0554 8.84491 14.5711C9.36063 15.0868 9.65039 15.7863 9.65039 16.5156C9.63353 16.9832 9.51786 17.4419 9.31104 17.8616C9.10421 18.2813 8.81091 18.6524 8.45038 18.9506C8.20838 19.1676 7.84138 19.4626 7.45038 19.7656H8.90137C9.10028 19.7656 9.29105 19.8446 9.4317 19.9853C9.57235 20.1259 9.65137 20.3167 9.65137 20.5156C9.65137 20.7145 9.57235 20.9053 9.4317 21.046C9.29105 21.1866 9.10028 21.2656 8.90137 21.2656Z"
        fill="currentColor"
      />
      <path
        d="M6.89913 11.265C6.70022 11.265 6.50945 11.186 6.36879 11.0453C6.22814 10.9047 6.14913 10.7139 6.14913 10.515V6.08296L5.52211 6.58797C5.44557 6.65109 5.3573 6.69837 5.26235 6.72713C5.16739 6.75588 5.06766 6.7655 4.96895 6.75545C4.87025 6.74539 4.77455 6.7159 4.68734 6.66859C4.60013 6.62128 4.52314 6.55713 4.4609 6.47987C4.39865 6.40261 4.35233 6.31374 4.32467 6.21846C4.297 6.12318 4.28852 6.0234 4.2997 5.92482C4.31088 5.82624 4.34155 5.73084 4.38985 5.64418C4.43816 5.55752 4.50317 5.48129 4.58114 5.41994L6.42812 3.93196C6.5383 3.84305 6.67142 3.78715 6.81203 3.77071C6.95265 3.75426 7.09502 3.77792 7.22274 3.839C7.35045 3.90009 7.45826 3.99607 7.53371 4.11586C7.60917 4.23565 7.64919 4.37437 7.64913 4.51595V10.5159C7.64886 10.7147 7.56972 10.9052 7.4291 11.0456C7.28847 11.186 7.09787 11.265 6.89913 11.265Z"
        fill="currentColor"
      />
      <path
        d="M20.9004 19.2656H12.2344C12.0355 19.2656 11.8447 19.1866 11.704 19.046C11.5634 18.9053 11.4844 18.7145 11.4844 18.5156C11.4844 18.3167 11.5634 18.1259 11.704 17.9853C11.8447 17.8446 12.0355 17.7656 12.2344 17.7656H20.9004C21.0993 17.7656 21.2901 17.8446 21.4307 17.9853C21.5714 18.1259 21.6504 18.3167 21.6504 18.5156C21.6504 18.7145 21.5714 18.9053 21.4307 19.046C21.2901 19.1866 21.0993 19.2656 20.9004 19.2656ZM20.9004 13.2656H12.2344C12.0355 13.2656 11.8447 13.1866 11.704 13.046C11.5634 12.9053 11.4844 12.7145 11.4844 12.5156C11.4844 12.3167 11.5634 12.1259 11.704 11.9853C11.8447 11.8446 12.0355 11.7656 12.2344 11.7656H20.9004C21.0993 11.7656 21.2901 11.8446 21.4307 11.9853C21.5714 12.1259 21.6504 12.3167 21.6504 12.5156C21.6504 12.7145 21.5714 12.9053 21.4307 13.046C21.2901 13.1866 21.0993 13.2656 20.9004 13.2656ZM20.9004 7.26562H12.2344C12.0355 7.26562 11.8447 7.18661 11.704 7.04596C11.5634 6.90531 11.4844 6.71454 11.4844 6.51562C11.4844 6.31671 11.5634 6.12594 11.704 5.98529C11.8447 5.84464 12.0355 5.76562 12.2344 5.76562H20.9004C21.0993 5.76562 21.2901 5.84464 21.4307 5.98529C21.5714 6.12594 21.6504 6.31671 21.6504 6.51562C21.6504 6.71454 21.5714 6.90531 21.4307 7.04596C21.2901 7.18661 21.0993 7.26562 20.9004 7.26562Z"
        fill="currentColor"
      />
    </svg>
  </SvgIcon>
)
