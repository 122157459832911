import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const Wifi: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <svg
      id="Wireless"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.7012 17.8874C11.0544 17.5547 11.5282 17.3533 12.0429 17.3533C12.5576 17.3533 13.0314 17.5547 13.3846 17.8874"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.16992 15.1635C9.17889 14.2149 10.5508 13.6301 12.0443 13.6301C13.5378 13.6301 14.9194 14.2149 15.9294 15.1635"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.58594 12.3893C7.27113 10.8053 9.5508 9.83618 12.0436 9.83618C14.5353 9.83618 16.816 10.8053 18.5109 12.3893"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.04297 9.65417C5.40437 7.45428 8.57237 6.11255 12.043 6.11255C15.5136 6.11255 18.6923 7.45428 21.043 9.6639"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </SvgIcon>
)
