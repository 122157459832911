/**
 * Map Sphere Engine code environment to Monaco language
 * @returns language string
 */

export enum Activity_Item_Coding_Language_Enum {
  CppGcc_11_1_0 = 'CPP_GCC_11_1_0',
  CGcc_11_1_0 = 'C_GCC_11_1_0',
  /** C# (.net 6.0 10.0) */
  CSharpDotnet_6_0_10_0 = 'C_SHARP_DOTNET_6_0_10_0',
  JavaHotspot_17_0_1 = 'JAVA_HOTSPOT_17_0_1',
  JavaHotspot_22_0 = 'JAVA_HOTSPOT_22_0',
  Nodejs_17_0_1 = 'NODEJS_17_0_1',
  Php_8_0_12 = 'PHP_8_0_12',
  Python_3_9_5 = 'PYTHON_3_9_5',
  R_4_0_4 = 'R_4_0_4',
  Scala_3_1_0 = 'SCALA_3_1_0',
  Sqlite_3_34_1 = 'SQLITE_3_34_1',
  Typescript_4_4_4 = 'TYPESCRIPT_4_4_4',
}

export const mapCodingLanguageValueToMonacoLanguage = (language: string) => {
  switch (language) {
    case Activity_Item_Coding_Language_Enum.Sqlite_3_34_1:
      return 'sql'
    case Activity_Item_Coding_Language_Enum.Nodejs_17_0_1:
      return 'javascript'
    case Activity_Item_Coding_Language_Enum.JavaHotspot_17_0_1:
    case Activity_Item_Coding_Language_Enum.JavaHotspot_22_0:
      return 'java'
    case Activity_Item_Coding_Language_Enum.CSharpDotnet_6_0_10_0:
      return 'csharp'
    default:
      return language.split('_')[0].toLowerCase()
  }
}
