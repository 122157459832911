import { SvgIcon, SvgIconProps } from '@mui/material'

export const LogOutCircle: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.9403 4.68959C13.7449 3.97152 12.3571 3.56055 10.8769 3.56055C6.38852 3.56055 2.75 7.33901 2.75 12C2.75 16.6609 6.38852 20.4394 10.8769 20.4394C12.3571 20.4394 13.7449 20.0284 14.9403 19.3104"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17.5078 8.24902L21.2509 11.9921M21.2509 11.9921L17.5078 15.7351M21.2509 11.9921H9.08594"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  )
}
