import { Box, useTheme } from '@mui/material'
import { useContext, useEffect, useRef } from 'react'

import { HardwareContext } from '../providers/HardwareProvider'

type CameraVisualizerProps = {
  coverSize?: boolean
}
export const CameraVisualizer = ({ coverSize }: CameraVisualizerProps) => {
  const { videoInputId } = useContext(HardwareContext)
  const theme = useTheme()
  const videoRef = useRef<HTMLVideoElement | null>(null)

  useEffect(() => {
    const startVideo = async () => {
      try {
        if (!videoInputId) {
          return
        }
        const stream = await navigator.mediaDevices.getUserMedia({
          video: { deviceId: { exact: videoInputId } },
        })
        if (videoRef.current) {
          videoRef.current.srcObject = stream
        }
      } catch (error) {
        // Do nothing for now
      }
    }

    startVideo()

    const currentRef = videoRef.current

    return () => {
      // Clean up the video stream when the component unmounts
      if (currentRef && currentRef.srcObject) {
        const mediaStream = currentRef.srcObject as MediaStream
        mediaStream.getTracks().forEach((track) => track.stop())
      }
    }
  }, [videoInputId])

  return (
    <Box
      component="video"
      ref={videoRef}
      autoPlay
      sx={{
        width: coverSize ? '100%' : { xs: 160, sm: 320 },
        height: coverSize ? 'calc(100% * 0.56)' : { xs: 100, sm: 200 },
        minHeight: 310,
        objectFit: 'cover',
        borderRadius: theme.spacing(2),
        aspectRatio: '16 / 9',
        background: theme.palette.grey[200],
      }}
      playsInline
      disablePictureInPicture
    />
  )
}
