import { localStorageGetter, localStorageSetter } from '@maki/shared/utils'
import * as Sentry from '@sentry/react'
import { createContext, ReactNode, useCallback, useState } from 'react'

export const MICROPHONE_ID = 'MICROPHONE_ID'
export const CAMERA_ID = 'CAMERA_ID'
export const SPEAKER_ID = 'SPEAKER_ID'

type RecordingMethodType = 'webcam' | 'microphone'

type HardwareContextType = {
  audioInputId: string | null
  videoInputId: string | null
  audioInputDevices: MediaDeviceInfo[]
  videoInputDevices: MediaDeviceInfo[]
  audioOutputId: string | null
  audioOutputDevices: MediaDeviceInfo[]
  initDevices: (recordingMethod: RecordingMethodType | undefined) => void
  setAudioInputId: (id: string) => void
  setVideoInputId: (id: string) => void
  setAudioOutputId: (id: string) => void
}

export const HardwareContext = createContext<HardwareContextType>({
  audioInputId: null,
  videoInputId: null,
  audioInputDevices: [] as MediaDeviceInfo[],
  videoInputDevices: [] as MediaDeviceInfo[],
  audioOutputId: null,
  audioOutputDevices: [] as MediaDeviceInfo[],
  initDevices: () => undefined,
  setAudioInputId: () => undefined,
  setVideoInputId: () => undefined,
  setAudioOutputId: () => undefined,
})

type HardwareProviderProps = {
  children: ReactNode
  enableHardwareDetection?: boolean
  onError?: (errorMessage: string) => void
}

export const HardwareProvider = ({ children, onError }: HardwareProviderProps) => {
  const [audioInputId, setAudioInputId] = useState<string | null>(
    localStorageGetter<string>(MICROPHONE_ID) || null,
  )
  const [videoInputId, setVideoInputId] = useState<string | null>(
    localStorageGetter<string>(CAMERA_ID) || null,
  )
  const [audioOutputId, setAudioOutputId] = useState<string | null>(
    localStorageGetter<string>(SPEAKER_ID) || null,
  )

  const [audioInputDevices, setAudioInputDevices] = useState<MediaDeviceInfo[]>([])
  const [videoInputDevices, setVideoInputDevices] = useState<MediaDeviceInfo[]>([])
  const [audioOutputDevices, setAudioOutputDevices] = useState<MediaDeviceInfo[]>([])

  const handleSetAudioInputId = useCallback(
    (id: string, force?: boolean) => {
      if (force || audioInputDevices.find((value) => value.deviceId === id)) {
        localStorageSetter(MICROPHONE_ID, id)
        setAudioInputId(id)
      }
    },
    [setAudioInputId, audioInputDevices],
  )

  const handleSetVideoInputId = useCallback(
    (id: string, force?: boolean) => {
      if (force || videoInputDevices.find((value) => value.deviceId === id)) {
        localStorageSetter(CAMERA_ID, id)
        setVideoInputId(id)
      }
    },
    [setVideoInputId, videoInputDevices],
  )

  const handleSetAudioOutputId = useCallback(
    (id: string, force?: boolean) => {
      if (force || audioOutputDevices.find((value) => value.deviceId === id)) {
        localStorageSetter(SPEAKER_ID, id)
        setAudioOutputId(id)
      }
    },
    [setAudioOutputId, audioOutputDevices],
  )

  const initDevices = useCallback(
    async (recordingMethod: RecordingMethodType | undefined, isProctoring?: boolean) => {
      const recordingConstraints = () => {
        if (isProctoring) {
          return { video: true, audio: false }
        }
        return recordingMethod === 'webcam'
          ? { video: true, audio: true }
          : { video: false, audio: true }
      }

      await navigator.mediaDevices.getUserMedia(recordingConstraints())
      const deviceInfos = await navigator.mediaDevices.enumerateDevices()

      const audioInputDevices = deviceInfos.filter((device) => device.kind === 'audioinput')
      if (audioInputDevices.length === 0) {
        const errorMessage = 'No audio input devices found'
        Sentry.withScope((scope) => {
          const error = new Error(errorMessage)
          scope.setTags({
            recordingMethod,
            method: 'initDevices',
            error: errorMessage,
            audioInputDevices: audioInputDevices.join(),
          })
          scope.setExtra('Video activity :: webcam', 'Webcam detection failed')
          Sentry.captureException(error)
        })
        onError?.(errorMessage)
      }
      setAudioInputDevices(audioInputDevices)
      if (!audioInputDevices.find((input) => input.deviceId === audioInputId)) {
        handleSetAudioInputId(audioInputDevices[0]?.deviceId, true)
      }

      const videoInputDevices = deviceInfos.filter((device) => device.kind === 'videoinput')
      if (videoInputDevices.length === 0) {
        const errorMessage = 'No video input devices found'
        Sentry.withScope((scope) => {
          const error = new Error(errorMessage)
          scope.setTags({
            recordingMethod,
            method: 'initDevices',
            error: errorMessage,
            videoInputDevices: videoInputDevices.join(),
          })
          Sentry.captureException(error)
        })
        onError?.(errorMessage)
      }
      setVideoInputDevices(videoInputDevices)
      if (!videoInputDevices.find((input) => input.deviceId === videoInputId)) {
        handleSetVideoInputId(videoInputDevices[0]?.deviceId, true)
      }

      const audioOutputDevices = deviceInfos.filter((device) => device.kind === 'audiooutput')
      if (audioOutputDevices.length === 0) {
        Sentry.withScope((scope) => {
          const errorMessage = 'No audio output devices found'
          const error = new Error(errorMessage)
          scope.setTags({
            recordingMethod,
            method: 'initDevices',
            error: errorMessage,
            audioOutputDevices: audioOutputDevices.join(),
          })
          Sentry.captureException(error)
        })
      }
      setAudioOutputDevices(audioOutputDevices)
      if (!audioOutputDevices.find((output) => output.deviceId === audioOutputId)) {
        handleSetAudioOutputId(audioOutputDevices[0]?.deviceId, true)
      }
    },
    [
      audioInputId,
      handleSetAudioInputId,
      onError,
      videoInputId,
      handleSetVideoInputId,
      audioOutputId,
      handleSetAudioOutputId,
    ],
  )

  return (
    <HardwareContext.Provider
      value={{
        audioInputId,
        videoInputId,
        audioInputDevices,
        videoInputDevices,
        audioOutputId,
        audioOutputDevices,
        initDevices,
        setAudioInputId: handleSetAudioInputId,
        setVideoInputId: handleSetVideoInputId,
        setAudioOutputId: handleSetAudioOutputId,
      }}
    >
      {children}
    </HardwareContext.Provider>
  )
}
