import { isString } from 'lodash'

export const localStorageSetter = (key: string, value: unknown) => {
  let valueToStore = value
  if (!isString(value)) {
    valueToStore = JSON.stringify(value)
  }
  localStorage.setItem(key, valueToStore as string)
}

/**
 * Typesafe localStorage.getItem()
 */
export const localStorageGetter = <T>(key: string): T | undefined => {
  const localStorageValue = localStorage.getItem(key)
  if (localStorageValue) {
    try {
      // Try to parse the value as JSON
      return JSON.parse(localStorageValue) as T
    } catch (e) {
      // If parsing fails, return the value as is
      return localStorageValue as unknown as T
    }
  }
  return undefined
}
