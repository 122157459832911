import { colorOpacityModifier, Spacer } from '@maki/shared/utils'
import { Box, IconButton, Typography, useTheme } from '@mui/material'
import { useCallback, useEffect, useRef, useState } from 'react'

const BAR_COUNT = 30
const BAR_SPACING = 4
const MIN_BAR_HEIGHT = 3
const MAX_BAR_HEIGHT = 20

interface AudioVisualizerProps {
  audioSrc: string
  PlayIcon: React.ReactNode
  PauseIcon: React.ReactNode
}

export const AudioVisualizer = ({ audioSrc, PlayIcon, PauseIcon }: AudioVisualizerProps) => {
  const theme = useTheme()
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const videoPlayerRef = useRef<HTMLVideoElement>(null)
  const [volumeLevels, setVolumeLevels] = useState<number[]>([])
  const [isPlaying, setIsPlaying] = useState(false)
  const [currentTime, setCurrentTime] = useState(0)
  const [duration, setDuration] = useState(0)
  const [audioBlob, setAudioBlob] = useState<Blob | null>(null)

  useEffect(() => {
    if (audioSrc && !audioBlob) {
      const fetchAndCreateBlob = async () => {
        try {
          const response = await fetch(audioSrc)
          const blob = await response.blob()
          setAudioBlob(blob)
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error('Error creating audio blob:', error)
        }
      }

      fetchAndCreateBlob()
    }
  }, [audioSrc, audioBlob])

  const drawRoundedBar = (
    ctx: CanvasRenderingContext2D,
    x: number,
    y: number,
    width: number,
    height: number,
    topRadius: number,
    bottomRadius: number,
  ) => {
    ctx.beginPath()
    ctx.moveTo(x + bottomRadius, y)
    ctx.lineTo(x + width - bottomRadius, y)
    ctx.quadraticCurveTo(x + width, y, x + width, y + bottomRadius)
    ctx.lineTo(x + width, y + height - topRadius)
    ctx.quadraticCurveTo(x + width, y + height, x + width - topRadius, y + height)
    ctx.lineTo(x + topRadius, y + height)
    ctx.quadraticCurveTo(x, y + height, x, y + height - topRadius)
    ctx.lineTo(x, y + bottomRadius)
    ctx.quadraticCurveTo(x, y, x + bottomRadius, y)
    ctx.closePath()
    ctx.fill()
  }

  useEffect(() => {
    const videoPlayerInstance = videoPlayerRef.current
    const computeVolumeLevels = async () => {
      if (!audioBlob) {
        // eslint-disable-next-line no-console
        console.error('No audio audioBlob provided')
        return
      }

      try {
        const arrayBuffer = await audioBlob.arrayBuffer()
        const audioContext = new AudioContext()
        const audioBuffer = await audioContext.decodeAudioData(arrayBuffer)

        setDuration(audioBuffer.duration)
        const rawData = audioBuffer.getChannelData(0) // Assuming mono audio
        const blockSize = Math.floor(rawData.length / BAR_COUNT)
        const levels: number[] = []

        for (let i = 0; i < BAR_COUNT; i++) {
          let sum = 0
          for (let j = 0; j < blockSize; j++) {
            sum += Math.abs(rawData[i * blockSize + j])
          }
          levels.push(sum / blockSize)
        }

        setVolumeLevels(levels)
        audioContext.close()
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error('Error processing audio audioBlob:', err)
      }
    }

    computeVolumeLevels()

    if (videoPlayerInstance) {
      videoPlayerInstance.addEventListener('timeupdate', handleTimeUpdate)
      videoPlayerInstance.addEventListener('ended', handleEnded)
    }

    return () => {
      if (videoPlayerInstance) {
        videoPlayerInstance.removeEventListener('timeupdate', handleTimeUpdate)
        videoPlayerInstance.removeEventListener('ended', handleEnded)
      }
    }
  }, [audioBlob])

  const handleTimeUpdate = () => {
    if (videoPlayerRef.current) {
      setCurrentTime(videoPlayerRef.current.currentTime)
    }
  }

  const handleEnded = () => {
    setIsPlaying(false)
    setCurrentTime(0)
  }

  useEffect(() => {
    const drawCanvas = () => {
      const canvas = canvasRef.current
      if (!canvas) {
        return
      }

      const canvasCtx = canvas.getContext('2d')
      if (!canvasCtx) {
        return
      }

      canvasCtx.clearRect(0, 0, canvas.width, canvas.height)

      const barWidth = (canvas.width - (BAR_COUNT - 1) * BAR_SPACING) / BAR_COUNT
      const maxHeight = canvas.height / 1.2 - 2

      const progress = currentTime / duration
      const progressBarCount = Math.floor(BAR_COUNT * progress)

      volumeLevels.forEach((level, i) => {
        const x = i * (barWidth + BAR_SPACING)
        const height = Math.min(level * maxHeight + MIN_BAR_HEIGHT, MAX_BAR_HEIGHT)
        const yTop = canvas.height / 2 - height
        const yBottom = canvas.height / 2
        const radius = barWidth / 2

        // Determine the color of the bar based on progress
        canvasCtx.fillStyle =
          i < progressBarCount
            ? theme.palette.primary.main
            : colorOpacityModifier(theme.palette.primary.main, 0.2)

        drawRoundedBar(canvasCtx, x, yTop, barWidth, height, 0, radius)
        drawRoundedBar(canvasCtx, x, yBottom, barWidth, height, radius, 0)
      })
    }

    drawCanvas()
  }, [volumeLevels, currentTime, duration, theme.palette.primary.main])

  const togglePlayPause = useCallback(() => {
    if (videoPlayerRef.current) {
      if (isPlaying) {
        videoPlayerRef.current.pause()
        setIsPlaying(false)
      } else {
        videoPlayerRef.current.play()
        setIsPlaying(true)
      }
    }
  }, [isPlaying])

  const formatTime = (timeInSeconds: number) => {
    const minutes = Math.floor(timeInSeconds / 60)
    const seconds = Math.floor(timeInSeconds % 60)
    return `${minutes}:${String(seconds).padStart(2, '0')}`
  }

  useEffect(() => {
    if (isPlaying && duration > 0 && currentTime > 0 && currentTime >= duration) {
      togglePlayPause()
    }
  }, [currentTime, duration, isPlaying, togglePlayPause])

  return (
    <>
      <video
        playsInline
        disablePictureInPicture
        ref={videoPlayerRef}
        controlsList="nodownload nofullscreen noremoteplayback"
        style={{ width: 0, height: 0 }}
      >
        <source src={audioSrc} />
      </video>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingBlock: 1,
          paddingInline: 2,
          backgroundColor: colorOpacityModifier(theme.palette.primary.main, 0.1),
          borderRadius: 10,
          width: 'auto',
          height: theme.spacing(7),
          boxShadow: `0px 4px 20px 0 rgba(1, 10, 92, 0.08)`,
        }}
      >
        <IconButton
          onClick={togglePlayPause}
          sx={{
            color: theme.palette.primary.main,
            transition: 'color 0.2s',
            '&:hover': {
              backgroundColor: 'transparent',
              color: theme.palette.primary.dark,
            },
            '&:focus, &:focus-visible': {
              backgroundColor: 'transparent',
              color: theme.palette.primary.dark,
              '> svg': {
                color: theme.palette.primary.main,
              },
            },
            '&:active': {
              backgroundColor: 'transparent',
              color: theme.palette.primary.dark,
            },
          }}
        >
          {isPlaying ? PauseIcon : PlayIcon}
        </IconButton>
        <Spacer type="horizontal" size={'sm'} />
        <canvas
          ref={canvasRef}
          width="600"
          height="80"
          style={{
            width: '150px',
            height: '40px',
          }}
        />
        <Spacer type="horizontal" size={'sm'} />
        <Box sx={{ opacity: 0.5, width: 100, display: 'flex', justifyContent: 'flex-end' }}>
          <Typography variant="body2" fontWeight={600}>
            {formatTime(currentTime)} / {formatTime(duration)}
          </Typography>
        </Box>
      </Box>
    </>
  )
}
