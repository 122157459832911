import { Markdown, useInterval } from '@maki/shared/utils'
import { Typography, TypographyProps } from '@mui/material'
import { useAnimation } from 'framer-motion'
import React, { useEffect, useRef, useState } from 'react'

interface AnimatedTextProps {
  text: string
  duration: number
  onComplete?: () => void
  variant?: TypographyProps['variant']
  sx?: TypographyProps['sx']
  hasMarkDown?: boolean
}

export const AnimatedText: React.FC<AnimatedTextProps> = ({
  text,
  duration,
  onComplete,
  variant = 'body1',
  hasMarkDown = false,
  sx = {},
}) => {
  const [displayedText, setDisplayedText] = useState('')
  const [animationComplete, setAnimationComplete] = useState(false)
  const controls = useAnimation()
  const currentIndexRef = useRef(0)

  const textRef = useRef(text)
  const durationRef = useRef(duration)
  const [textAnimation, setTextAnimation] = useState(false)

  const intervalTime = (durationRef.current * 1000) / textRef.current?.length

  useInterval(() => {
    if (
      textAnimation &&
      textRef.current.length > 0 &&
      currentIndexRef.current < textRef.current.length &&
      duration > 0
    ) {
      setDisplayedText(textRef.current.slice(0, currentIndexRef.current + 1))
      currentIndexRef.current++
    } else {
      if (!animationComplete && currentIndexRef.current === textRef.current.length) {
        setAnimationComplete(true)
        if (onComplete) {
          onComplete()
        }
      }
    }
  }, intervalTime)

  useEffect(() => {
    const startAnimation = async () => {
      await controls.start('visible')
      setTextAnimation(true)
    }

    if (duration > 0 && !animationComplete && currentIndexRef.current === 0 && !textAnimation) {
      startAnimation()
    }
  }, [animationComplete, controls, duration, textAnimation])

  if (hasMarkDown) {
    return (
      <Typography variant={variant} fontWeight={variant === 'h5' ? 500 : 400} sx={sx}>
        <Markdown>{displayedText}</Markdown>
      </Typography>
    )
  }

  return (
    <Typography variant={variant} fontWeight={variant === 'h5' ? 500 : 400} sx={sx}>
      {displayedText}
    </Typography>
  )
}
