import { SvgIcon, SvgIconProps } from '@mui/material'

export const PlusSquared: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <title>Iconly/Light/Plus</title>
        <g
          id="Iconly/Light/Plus"
          stroke="none"
          strokeWidth="1.5"
          fill="none"
          fillRule="evenodd"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <g
            id="Plus"
            transform="translate(2.000000, 2.000000)"
            stroke="currentColor"
            strokeWidth="1.5"
          >
            <line x1="10" y1="6.32730733" x2="10" y2="13.6536632" id="Line_185" />
            <line x1="13.6666667" y1="9.99048525" x2="6.33333333" y2="9.99048525" id="Line_186" />
            <path
              d="M14.6857143,0 L5.31428571,0 C2.04761905,0 0,2.31208373 0,5.58515699 L0,14.414843 C0,17.6879163 2.03809524,20 5.31428571,20 L14.6857143,20 C17.9619048,20 20,17.6879163 20,14.414843 L20,5.58515699 C20,2.31208373 17.9619048,0 14.6857143,0 Z"
              id="Path"
            />
          </g>
        </g>
      </svg>
    </SvgIcon>
  )
}
