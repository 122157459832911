import {
  Box,
  MenuItem,
  Select,
  SelectChangeEvent,
  SelectProps,
  Theme,
  useMediaQuery,
} from '@mui/material'
import { CSSProperties } from '@mui/styled-engine'
import { useCallback, useContext } from 'react'

import { HardwareContext } from '../providers/HardwareProvider'

type CameraSelectorProps = {
  theme: Theme
  brandingThemeStyling?: CSSProperties
  Icon?: React.ReactNode
} & SelectProps
export const CameraSelector = ({ theme, brandingThemeStyling }: CameraSelectorProps) => {
  const { videoInputId, videoInputDevices, setVideoInputId } = useContext(HardwareContext)
  const belowSmBreakpoint = useMediaQuery(theme.breakpoints.down('sm'))

  const handleSetAudioInputId = useCallback(
    (event: SelectChangeEvent<string | null>) => {
      event.preventDefault()
      if (event.target.value) {
        setVideoInputId(event.target.value)
      }
    },
    [setVideoInputId],
  )

  return (
    <Box display="flex" gap={theme.spacing(2)}>
      <Select
        variant="outlined"
        data-testid="camera-select"
        labelId="camera-select-label"
        sx={{
          maxWidth: { xs: '95%', sm: 'initial' },
          ...brandingThemeStyling,
        }}
        size={belowSmBreakpoint ? 'small' : 'medium'}
        value={videoInputId}
        onChange={handleSetAudioInputId}
      >
        {videoInputDevices.map((input) => (
          <MenuItem key={input.deviceId} value={input.deviceId}>
            {input.label || `Camera ${input.deviceId}`}
          </MenuItem>
        ))}
      </Select>
    </Box>
  )
}
