import { Theme } from '@mui/material'
import { TFunction } from 'i18next'

import { Book, Link, MessagesWord, Microphone, Target } from '../../../assets/src/lib/icons'

export enum FlatsDimensionEnum {
  GRAMMATICAL_RANGE_AND_ACCURACY = 'grammatical_range_and_accuracy',
  TASK_ACHIEVEMENT = 'task_achievement',
  COHERENCE_AND_COHESION = 'coherence_and_cohesion',
  LEXICAL_RESOURCE = 'lexical_resource',
  NO_ANSWER = 'no_answer',
  // SLATS
  PRONUNCIATION = 'pronunciation',
  // FOUNDEVER INDIA SCORING ALGORITHM VARIANT
  CONVERSATIONAL_ABILITY = 'conversational_ability',
  GRAMMAR_ASSESSMENT = 'grammar_assessment',
  MANNER_OF_SPEAKING = 'manner_of_speaking',
}

export const getDimensionLabel = (t: TFunction<'translation'>, dimension: string) => {
  switch (dimension) {
    case FlatsDimensionEnum.GRAMMATICAL_RANGE_AND_ACCURACY:
      return t(
        'recruiters.assessment.candidates.details.hardSkills.flats.grammatical_range_and_accuracy',
        'Grammatical versatility',
      )
    case FlatsDimensionEnum.TASK_ACHIEVEMENT:
      return t(
        'recruiters.assessment.candidates.details.hardSkills.flats.task_achievement',
        'Task achievement',
      )
    case FlatsDimensionEnum.COHERENCE_AND_COHESION:
      return t(
        'recruiters.assessment.candidates.details.hardSkills.flats.coherence_and_cohesion',
        'Logical connectivity',
      )
    case FlatsDimensionEnum.LEXICAL_RESOURCE:
      return t(
        'recruiters.assessment.candidates.details.hardSkills.flats.lexical_resource',
        'Lexical depth',
      )
    case FlatsDimensionEnum.PRONUNCIATION:
      return t(
        'recruiters.assessment.candidates.details.hardSkills.flats.pronunciation',
        'Pronunciation',
      )
    case FlatsDimensionEnum.CONVERSATIONAL_ABILITY:
      return t(
        'recruiters.assessment.candidates.details.hardSkills.flats.conversational_ability',
        'Conversational ability',
      )
    case FlatsDimensionEnum.GRAMMAR_ASSESSMENT:
      return t(
        'recruiters.assessment.candidates.details.hardSkills.flats.grammar_assessment',
        'Grammar assessment',
      )
    case FlatsDimensionEnum.MANNER_OF_SPEAKING:
      return t(
        'recruiters.assessment.candidates.details.hardSkills.flats.manner_of_speaking',
        'Manner of speaking',
      )
    default:
      return dimension
  }
}

export const getDimensionIcon = (theme: Theme, dimension: string) => {
  switch (dimension) {
    case FlatsDimensionEnum.GRAMMATICAL_RANGE_AND_ACCURACY:
    case FlatsDimensionEnum.GRAMMAR_ASSESSMENT:
      return <MessagesWord stroke={theme.palette.text.secondary} />
    case FlatsDimensionEnum.TASK_ACHIEVEMENT:
      return <Target fill={theme.palette.text.secondary} />
    case FlatsDimensionEnum.COHERENCE_AND_COHESION:
    case FlatsDimensionEnum.MANNER_OF_SPEAKING:
      return <Link stroke={theme.palette.text.secondary} />
    case FlatsDimensionEnum.LEXICAL_RESOURCE:
    case FlatsDimensionEnum.CONVERSATIONAL_ABILITY:
      return <Book stroke={theme.palette.text.secondary} />
    case FlatsDimensionEnum.PRONUNCIATION:
      return <Microphone stroke={theme.palette.text.secondary} />
    default:
      return null
  }
}

export const getPronunciationLabel = (t: TFunction<'translation'>, detailName: string) => {
  switch (detailName) {
    case 'fluency':
      return t('recruiters.assessment.candidates.details.hardSkills.slats.fluency', 'Fluency:')
    case 'speed':
      return t('recruiters.assessment.candidates.details.hardSkills.slats.speed', 'Speed:')
    case 'rhythm':
      return t('recruiters.assessment.candidates.details.hardSkills.flats.rhythm', 'Rhythm:')
    default:
      return null
  }
}

export const getPronunciationTooltip = (t: TFunction<'translation'>, detailName: string) => {
  switch (detailName) {
    case 'fluency':
      return t(
        'recruiters.assessment.candidates.details.hardSkills.slats.fluency.tooltip',
        'The fluency score indicates how smoothly the speaker delivers their speech',
      )
    case 'speed':
      return t(
        'recruiters.assessment.candidates.details.hardSkills.slats.speed.tooltip',
        'Speech rate in words per minute.',
      )
    case 'rhythm':
      return t(
        'recruiters.assessment.candidates.details.hardSkills.flats.rhythm.tooltip',
        "Rhythm score assesses the user's ability to use suprasegmental features, such as tone and stress, to convey meaning and create natural-sounding speech",
      )
    default:
      return null
  }
}
