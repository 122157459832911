import { SvgIcon, SvgIconProps } from '@mui/material'

export const Docs: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <svg
        width={props.width}
        height={props.height}
        className={props.className}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.6907 13.1673L19.0401 19.2792C18.7661 20.2945 17.8458 20.9997 16.7944 20.9997H7.20448C6.15315 20.9997 5.23186 20.2945 4.95778 19.2792L3.30827 13.1673C2.90915 11.6888 4.02249 10.2344 5.55396 10.2344H18.444C19.9764 10.2344 21.0898 11.6888 20.6907 13.1673Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.68164 6.378H18.3156M8.4364 3H15.5606"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  )
}
