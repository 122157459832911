/**
 * This utility allows to compute darker & lighter MUI primary color values
 *
 * Source
 * https://gist.github.com/renancouto/4675192?permalink_comment_id=2976324#gistcomment-2976324
 */

export const colorLuminosityModifier = (color: string, percent: number) => {
  const num = parseInt(color.replace('#', ''), 16),
    amt = Math.round(2.55 * percent),
    R = (num >> 16) + amt,
    B = ((num >> 8) & 0x00ff) + amt,
    G = (num & 0x0000ff) + amt

  return (
    '#' +
    (
      0x1000000 +
      (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 +
      (B < 255 ? (B < 1 ? 0 : B) : 255) * 0x100 +
      (G < 255 ? (G < 1 ? 0 : G) : 255)
    )
      .toString(16)
      .slice(1)
  )
}

export const colorOpacityModifier = (color: string, opacity: number) => {
  const num = parseInt(color.replace('#', ''), 16),
    R = num >> 16,
    G = (num >> 8) & 0x00ff,
    B = num & 0x0000ff

  return `rgba(${R}, ${G}, ${B}, ${opacity})`
}
