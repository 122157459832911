import {
  bgBG,
  csCZ,
  daDK,
  deDE,
  elGR,
  enUS,
  esES,
  etEE,
  fiFI,
  frFR,
  heIL,
  hiIN,
  hrHR,
  huHU,
  itIT,
  jaJP,
  koKR,
  msMS,
  nbNO,
  nlNL,
  plPL,
  ptBR,
  ptPT,
  roRO,
  ruRU,
  svSE,
  thTH,
  trTR,
  viVN,
  zhCN,
  zhHK,
} from '@mui/material/locale'
import {
  bgBG as dataGridBG,
  csCZ as dataGridCS_CZ,
  daDK as dataGridDA_DK,
  deDE as dataGridDE,
  elGR as dataGridEL_GR,
  enUS as dataGridEN,
  esES as dataGridES,
  fiFI as dataGridFI_FI,
  frFR as dataGridFR,
  heIL as dataGridHE_IL,
  huHU as dataGridHU_HU,
  itIT as dataGridIT,
  jaJP as dataGridJA_JP,
  koKR as dataGridKO_KR,
  nbNO as dataGridNO_NO,
  nlNL as dataGridNL,
  plPL as dataGridPL_PL,
  ptBR as dataGridPT,
  roRO as dataGridRO_RO,
  ruRU as dataGridRU_RU,
  svSE as dataGridSV_SE,
  trTR as dataGridTR_TR,
  viVN as dataGridVI_VN,
  zhCN as dataGridZH_CN,
} from '@mui/x-data-grid-premium'
import {
  csCZ as datePickersCS_CZ,
  deDE as datePickersDE,
  enUS as datePickersEN,
  esES as datePickersES,
  frFR as datePickersFR,
  huHU as datePickersHU_HU,
  itIT as datePickersIT,
  jaJP as datePickersJA_JP,
  koKR as datePickersKO_KR,
  nlNL as datePickersNL,
  plPL as datePickersPL_PL,
  ptBR as datePickersPT,
  ptBR as datePickersPT_BR,
  ruRU as datePickersRU_RU,
  svSE as datePickersSV_SE,
  trTR as datePickersTR_TR,
  zhCN as datePickersZH_CN,
} from '@mui/x-date-pickers'

export const MUI_LOCALES = {
  fr: frFR,
  it: itIT,
  pt: ptPT,
  nl: nlNL,
  de: deDE,
  es: esES,
  en: enUS,
  sv: svSE,
  el: elGR,
  pl: plPL,
  ms: msMS,
  vi: viVN,
  ko: koKR,
  ro: roRO,
  hu: huHU,
  bg: bgBG,
  frCA: frFR,
  ptBR,
  trTR,
  daDK,
  noNO: nbNO,
  ruRU,
  fiFI,
  sqAL: enUS, // missing locale
  zhHK,
  hrHR,
  csCZ,
  etEE,
  heIL,
  hiIN,
  jaJP,
  lvLV: enUS, // missing locale
  ltLT: enUS, // missing locale
  zhCN,
  thTH,
}

export const DATEPICKERS_LOCALES = {
  fr: datePickersFR,
  it: datePickersIT,
  pt: datePickersPT,
  nl: datePickersNL,
  de: datePickersDE,
  es: datePickersES,
  en: datePickersEN,
  sv: datePickersSV_SE,
  el: datePickersEN, // missing locale
  pl: datePickersPL_PL,
  ms: datePickersEN, // missing locale
  vi: datePickersEN, // missing locale
  ko: datePickersKO_KR,
  ro: datePickersEN, // missing locale
  hu: datePickersHU_HU,
  bg: datePickersEN, // missing locale
  frCA: datePickersFR,
  ptBR: datePickersPT_BR,
  trTR: datePickersTR_TR,
  daDK: datePickersEN, // missing locale
  noNO: datePickersEN, // missing locale
  ruRU: datePickersRU_RU,
  fiFI: datePickersEN, // missing locale
  sqAL: datePickersEN, // missing locale
  zhHK: datePickersZH_CN,
  hrHR: datePickersEN, // missing locale
  csCZ: datePickersCS_CZ,
  etEE: datePickersEN, // missing locale
  heIL: datePickersEN, // missing locale
  hiIN: datePickersEN, // missing locale
  jaJP: datePickersJA_JP,
  lvLV: datePickersEN, // missing locale
  ltLT: datePickersEN, // missing locale
  zhCN: datePickersZH_CN,
  thTH: datePickersEN, // missing locale
  srRS: datePickersEN, // missing locale
  skSK: datePickersEN, // missing locale
  slSI: datePickersEN, // missing locale
  nlBE: datePickersEN, // missing locale
  isIS: datePickersEN, // missing locale
  esMX: datePickersEN, // missing locale
}

export const DATAGRID_LOCALES = {
  fr: dataGridFR,
  it: dataGridIT,
  pt: dataGridPT,
  nl: dataGridNL,
  de: dataGridDE,
  es: dataGridES,
  en: dataGridEN,
  sv: dataGridSV_SE,
  el: dataGridEL_GR,
  pl: dataGridPL_PL,
  ms: dataGridEN, // missing locale
  vi: dataGridVI_VN,
  ko: dataGridKO_KR,
  ro: dataGridRO_RO,
  hu: dataGridHU_HU,
  bg: dataGridBG,
  frCA: dataGridFR,
  ptBR: dataGridPT,
  trTR: dataGridTR_TR,
  daDK: dataGridDA_DK,
  noNO: dataGridNO_NO,
  ruRU: dataGridRU_RU,
  fiFI: dataGridFI_FI,
  sqAL: dataGridEN, // missing locale
  zhHK: dataGridEN, // missing locale
  hrHR: dataGridEN, // missing locale
  csCZ: dataGridCS_CZ,
  etEE: dataGridEN, // missing locale
  heIL: dataGridHE_IL,
  hiIN: dataGridEN, // missing locale
  jaJP: dataGridJA_JP,
  lvLV: dataGridEN, // missing locale
  ltLT: dataGridEN, // missing locale
  zhCN: dataGridZH_CN,
  thTH: dataGridEN, // missing locale
  srRS: dataGridEN, // missing locale
  skSK: dataGridEN, // missing locale
  slSI: dataGridEN, // missing locale
  nlBE: dataGridEN, // missing locale
  isIS: dataGridEN, // missing locale
  esMX: dataGridEN, // missing locale
}
