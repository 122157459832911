import { SvgIcon, SvgIconProps } from '@mui/material'

export const MakiLogo: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M28.0716 19.8537C27.5965 21.3187 27.0067 22.3463 26.4802 23.1198C23.978 22.8553 22.8533 23.9783 23.1182 26.4813C22.3539 27.0016 21.3184 27.5972 19.8529 28.0723C19.4407 21.9516 21.9525 19.4401 28.0716 19.8537ZM12.1458 28.0719C10.6804 27.5968 9.64486 27.0012 8.88055 26.4809C9.1455 23.9779 8.02081 22.8544 5.51859 23.1194C4.99203 22.3459 4.40227 21.3183 3.92719 19.8533C10.0463 19.4397 12.5581 21.9511 12.1458 28.0719ZM19.8529 3.9271C21.3184 4.40218 22.3539 4.9978 23.1182 5.51808C22.8533 8.02113 23.978 9.14458 26.4802 8.87962C27.0067 9.65314 27.5965 10.6807 28.0716 12.1457C21.9525 12.5593 19.4407 10.0479 19.8529 3.9271ZM3.92719 12.1457C4.40227 10.6807 4.99203 9.65314 5.51859 8.87962C8.02081 9.14416 9.1455 8.02113 8.88055 5.51808C9.64486 4.9978 10.6804 4.40218 12.1458 3.9271C12.5581 10.0479 10.0463 12.5593 3.92719 12.1457ZM28.5701 14.4094C19.9643 14.2524 17.7467 12.0335 17.5897 3.429C16.6057 3.30134 15.3922 3.30134 14.4094 3.429C14.2521 12.0323 12.0341 14.2524 3.42909 14.4094C3.30143 15.393 3.30143 16.6064 3.42909 17.5896C11.9926 17.7458 14.2546 19.9638 14.4099 28.5704C15.3927 28.6981 16.6065 28.6972 17.5902 28.5704C17.7475 19.9634 19.9647 17.7449 28.5709 17.5896C28.6986 16.6064 28.6986 15.393 28.5709 14.4094H28.5701ZM16 21.091C16 18.4017 13.5978 15.9995 10.9085 15.9995C13.5978 15.9995 15.9996 13.5923 16 10.908C15.9929 13.5923 18.4022 15.9999 21.0915 15.9995C18.4022 15.9995 15.9996 18.4017 16 21.091Z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  )
}
